import { mediaResponseEntity } from '../media'

export const postResponseEsMock: StrapiDataItem<PostResponseEntity> = {
  id: 2,
  attributes: {
    title: 'Entrada de test',
    summary: 'Esta es una entrada de test',
    content: '{"time":123456789,"version":"","blocks":[]}',
    locale: 'es',
    imgUrl: 'https://example.com/test.png',
    createdAt: '2022-04-01T00:00:00.000Z',
    updatedAt: '2022-04-01T00:00:00.000Z',
    publishedAt: '2022-04-01T00:00:00.000Z',
    categories: {
      data: [
        {
          id: 1,
          attributes: {
            code: 'test-category',
            main: true,
            name: 'Test Category',
            description: 'This is a test category',
            createdAt: '2022-04-01T00:00:00.000Z',
            updatedAt: '2022-04-01T00:00:00.000Z',
          },
        },
      ],
    },
    coverImage: {
      data: mediaResponseEntity,
    },
    coverImageAuthor: null,
    coverImageSourceUrl: null,
    localizations: undefined,
    writer: {
      data: {
        id: 1,
        attributes: {
          name: 'Test Writer',
          createdAt: '2022-04-01T00:00:00.000Z',
          updatedAt: '2022-04-01T00:00:00.000Z',
          avatar: 'https://example.com/test-avatar.png',
          twitter: 'testwriter',
          personalUrl: 'https://example.com/test-writer',
        },
      },
    },
  },
}

export const postResponseEnMock: StrapiDataItem<PostResponseEntity> = {
  id: 1,
  attributes: {
    title: 'Test Post',
    summary: 'This is a test post',
    content: '{"time":123456789,"version":"","blocks":[]}',
    locale: 'en',
    imgUrl: 'https://example.com/test.png',
    createdAt: '2022-04-01T00:00:00.000Z',
    updatedAt: '2022-04-01T00:00:00.000Z',
    publishedAt: '2022-04-01T00:00:00.000Z',
    categories: {
      data: [
        {
          id: 1,
          attributes: {
            code: 'test-category',
            main: true,
            name: 'Test Category',
            description: 'This is a test category',
            createdAt: '2022-04-01T00:00:00.000Z',
            updatedAt: '2022-04-01T00:00:00.000Z',
          },
        },
      ],
    },
    coverImage: {
      data: mediaResponseEntity,
    },
    coverImageAuthor: null,
    coverImageSourceUrl: null,
    localizations: { data: [postResponseEsMock] },
    writer: {
      data: {
        id: 1,
        attributes: {
          name: 'Test Writer',
          createdAt: '2022-04-01T00:00:00.000Z',
          updatedAt: '2022-04-01T00:00:00.000Z',
          avatar: 'https://example.com/test-avatar.png',
          twitter: 'testwriter',
          personalUrl: 'https://example.com/test-writer',
        },
      },
    },
  },
}

export const postMock: Post = {
  categories: [
    {
      code: 'test-category',
      createdAt: '2022-04-01T00:00:00.000Z',
      description: 'This is a test category',
      id: 1,
      localizedName: 'Test Category',
      main: true,
      name: 'Test Category',
      updatedAt: '2022-04-01T00:00:00.000Z',
    },
  ],
  content: {
    blocks: [],
    time: 123456789,
    version: '',
  },
  coverImage: {
    alternativeText: 'test alt text',
    caption: 'test caption',
    createdAt: '2022-04-01T12:00:00.000Z',
    ext: 'jpg',
    formats: {
      large: {
        ext: 'jpg',
        hash: 'testhash',
        height: 675,
        mime: 'image/jpeg',
        name: 'large_test',
        path: null,
        size: 400,
        url: '/media/large_test.jpg',
        width: 1000,
      },
      medium: {
        ext: 'jpg',
        hash: 'testhash',
        height: 375,
        mime: 'image/jpeg',
        name: 'medium_test',
        path: null,
        size: 200,
        url: '/media/medium_test.jpg',
        width: 750,
      },
      small: {
        ext: 'jpg',
        hash: 'testhash',
        height: 250,
        mime: 'image/jpeg',
        name: 'small_test',
        path: null,
        size: 100,
        url: '/media/small_test.jpg',
        width: 500,
      },
      thumbnail: {
        ext: 'jpg',
        hash: 'testhash',
        height: 25,
        mime: 'image/jpeg',
        name: 'thumbnail_test',
        path: null,
        size: 10,
        url: '/media/thumbnail_test.jpg',
        width: 15,
      },
    },
    hash: 'testhash',
    height: 500,
    id: 1,
    mime: 'image/jpeg',
    name: 'test image',
    previewUrl: null,
    provider: 'provider',
    size: 300,
    updatedAt: '2022-04-01T12:00:00.000Z',
    url: '/media/test.jpg',
    width: 1000,
  },
  coverImageAuthor: null,
  coverImageSourceUrl: null,
  createdAt: '2022-04-01T00:00:00.000Z',
  id: 1,
  imgUrl: 'https://example.com/test.png',
  locale: 'en',
  localizations: [
    {
      categories: [
        {
          code: 'test-category',
          createdAt: '2022-04-01T00:00:00.000Z',
          description: 'This is a test category',
          id: 1,
          localizedName: 'Test Category',
          main: true,
          name: 'Test Category',
          updatedAt: '2022-04-01T00:00:00.000Z',
        },
      ],
      content: {
        blocks: [],
        time: 123456789,
        version: '',
      },
      coverImage: {
        alternativeText: 'test alt text',
        caption: 'test caption',
        createdAt: '2022-04-01T12:00:00.000Z',
        ext: 'jpg',
        formats: {
          large: {
            ext: 'jpg',
            hash: 'testhash',
            height: 675,
            mime: 'image/jpeg',
            name: 'large_test',
            path: null,
            size: 400,
            url: '/media/large_test.jpg',
            width: 1000,
          },
          medium: {
            ext: 'jpg',
            hash: 'testhash',
            height: 375,
            mime: 'image/jpeg',
            name: 'medium_test',
            path: null,
            size: 200,
            url: '/media/medium_test.jpg',
            width: 750,
          },
          small: {
            ext: 'jpg',
            hash: 'testhash',
            height: 250,
            mime: 'image/jpeg',
            name: 'small_test',
            path: null,
            size: 100,
            url: '/media/small_test.jpg',
            width: 500,
          },
          thumbnail: {
            ext: 'jpg',
            hash: 'testhash',
            height: 25,
            mime: 'image/jpeg',
            name: 'thumbnail_test',
            path: null,
            size: 10,
            url: '/media/thumbnail_test.jpg',
            width: 15,
          },
        },
        hash: 'testhash',
        height: 500,
        id: 1,
        mime: 'image/jpeg',
        name: 'test image',
        previewUrl: null,
        provider: 'provider',
        size: 300,
        updatedAt: '2022-04-01T12:00:00.000Z',
        url: '/media/test.jpg',
        width: 1000,
      },
      coverImageAuthor: null,
      coverImageSourceUrl: null,
      createdAt: '2022-04-01T00:00:00.000Z',
      id: 2,
      imgUrl: 'https://example.com/test.png',
      locale: 'es',
      localizations: null,
      publishedAt: '2022-04-01T00:00:00.000Z',
      summary: 'Esta es una entrada de test',
      title: 'Entrada de test',
      updatedAt: '2022-04-01T00:00:00.000Z',
      writer: {
        avatar: 'https://example.com/test-avatar.png',
        createdAt: '2022-04-01T00:00:00.000Z',
        id: 1,
        name: 'Test Writer',
        personalUrl: 'https://example.com/test-writer',
        twitter: 'testwriter',
        updatedAt: '2022-04-01T00:00:00.000Z',
      },
    },
  ],
  publishedAt: '2022-04-01T00:00:00.000Z',
  summary: 'This is a test post',
  title: 'Test Post',
  updatedAt: '2022-04-01T00:00:00.000Z',
  writer: {
    avatar: 'https://example.com/test-avatar.png',
    createdAt: '2022-04-01T00:00:00.000Z',
    id: 1,
    name: 'Test Writer',
    personalUrl: 'https://example.com/test-writer',
    twitter: 'testwriter',
    updatedAt: '2022-04-01T00:00:00.000Z',
  },
}
